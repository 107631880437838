.adyen-checkout__checkbox {
  display: flex;
  width: 100%;
}
.adyen-checkout__checkbox__label {
  flex: 1;
  position: relative;
  padding-left: var(--adyen-sdk-spacer-090, 24px);
  cursor: pointer;
  display: inline-block;
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  font-weight: normal;
  user-select: none;
}
[dir=rtl] .adyen-checkout__checkbox__label {
  padding-left: 0;
  padding-right: var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout__checkbox__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  /* Check */
  /* Box */
}
.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::before {
  opacity: 1;
}
.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::after {
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-label-primary, #00112c);
  background-color: var(--adyen-sdk-color-label-primary, #00112c);
}
.adyen-checkout__checkbox__input:checked:hover + .adyen-checkout__checkbox__label::after {
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-tertiary, #8d95a3);
  border-color: var(--adyen-sdk-color-label-primary, #00112c);
}
.adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label::after {
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-label-primary, #00112c);
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-tertiary, #8d95a3);
}
.adyen-checkout__checkbox__input:hover:not(:focus) + .adyen-checkout__checkbox__label::after {
  border-color: var(--adyen-sdk-color-outline-tertiary, #8d95a3);
  box-shadow: 0 0 0 var(--adyen-sdk-spacer-010, 2px) var(--adyen-sdk-color-outline-primary-hover, #c9cdd3);
}
.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::before {
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: var(--adyen-sdk-spacer-010, 2px) solid var(--adyen-sdk-color-label-on-color, #ffffff);
  border-right: var(--adyen-sdk-spacer-010, 2px) solid var(--adyen-sdk-color-label-on-color, #ffffff);
  border-radius: 0 var(--adyen-sdk-spacer-010, 2px) 1px;
  content: "";
  height: 11px;
  left: 1px;
  opacity: 0;
  position: absolute;
  top: var(--adyen-sdk-spacer-010, 2px);
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
  transition: opacity 0.2s ease-out;
  width: var(--adyen-sdk-spacer-030, 6px);
  z-index: 1;
}
[dir=rtl] .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::before {
  left: auto;
  right: var(--adyen-sdk-spacer-040, 8px);
}
.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--adyen-sdk-spacer-070, 16px);
  height: var(--adyen-sdk-spacer-070, 16px);
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  background-color: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-primary, #dbdee2);
  z-index: 0;
  transition: background 0.15s ease-out, border 0.05s ease-out, box-shadow 0.1s ease-out;
}
[dir=rtl] .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::after {
  left: auto;
  right: 0;
}

.adyen-checkout__field--consentCheckbox {
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-primary, #dbdee2);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  display: flex;
  padding: var(--adyen-sdk-spacer-060, 12px) var(--adyen-sdk-spacer-070, 16px);
  align-items: center;
}
[dir=rtl] .adyen-checkout__field--consentCheckbox {
  padding: var(--adyen-sdk-spacer-060, 12px) var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout__field--consentCheckbox .adyen-checkout__input-wrapper {
  flex: 1;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.adyen-checkout__field--consentCheckbox .adyen-checkout__input-wrapper:active, .adyen-checkout__field--consentCheckbox .adyen-checkout__input-wrapper:active:hover, .adyen-checkout__field--consentCheckbox .adyen-checkout__input-wrapper:focus-within, .adyen-checkout__field--consentCheckbox .adyen-checkout__input-wrapper:focus-within:hover {
  box-shadow: none;
}
.adyen-checkout__field--consentCheckbox.adyen-checkout__field--error {
  border-color: var(--adyen-sdk-color-outline-critical, #e22d2d);
}
.adyen-checkout__field--consentCheckbox .adyen-checkout-input__inline-validation {
  margin-right: 0;
}
[dir=rtl] .adyen-checkout__field--consentCheckbox .adyen-checkout-input__inline-validation {
  margin-left: 0;
}