.adyen-checkout-timeline {
  scrollbar-color: var(--adyen-sdk-color-background-quaternary, #c0c5cc) transparent;
}

.adyen-checkout-timeline__items {
  all: unset;
}

.adyen-checkout-timeline-item {
  scrollbar-color: var(--adyen-sdk-color-background-quaternary, #c0c5cc) transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  list-style-type: none;
  max-width: 500px;
}
.adyen-checkout-timeline-item:last-child .adyen-checkout-timeline-item__separator {
  display: none;
}
.adyen-checkout-timeline-item:last-child .adyen-checkout-timeline-item__content {
  padding-bottom: 0;
}

.adyen-checkout-timeline-item__row {
  display: flex;
  gap: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout-timeline-item__marker {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: var(--adyen-sdk-spacer-070, 16px);
  margin-top: var(--adyen-sdk-spacer-010, 2px);
}

.adyen-checkout-timeline-item__separator {
  flex-grow: 1;
  position: relative;
}
.adyen-checkout-timeline-item__separator::before {
  background-color: var(--adyen-sdk-color-separator-secondary, #c9cdd3);
  content: "";
  height: 100%;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 1px;
}

.adyen-checkout-timeline-item__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: var(--adyen-sdk-spacer-060, 12px);
}

.adyen-checkout-timeline-item__title {
  font-size: var(--adyen-sdk-text-caption-font-size, 0.75rem);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
  letter-spacing: 0;
  text-align: left;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  margin-bottom: var(--adyen-sdk-spacer-010, 2px);
}