.adyen-checkout__payment-method__disable-confirmation {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows var(--adyen-sdk-animation-duration-moderate, 150ms) var(--adyen-sdk-animation-easing-linear, linear), opacity var(--adyen-sdk-animation-duration-moderate, 150ms) var(--adyen-sdk-animation-easing-standard, cubic-bezier(0.2, 0, 0.4, 0.9)), margin-bottom var(--adyen-sdk-animation-duration-fast, 100ms) var(--adyen-sdk-animation-easing-linear, linear);
  background: var(--adyen-sdk-color-background-critical-strong, #e22d2d);
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
  border-right: 1px solid #e12424;
  border-left: 1px solid #e12424;
  overflow: hidden;
  opacity: 0;
  margin: 0 -17px;
}
.adyen-checkout__payment-method__disable-confirmation__content {
  min-height: 0;
  transition: visibility var(--adyen-sdk-animation-duration-moderate, 150ms) var(--adyen-sdk-animation-easing-linear, linear), padding var(--adyen-sdk-animation-duration-fast, 100ms) var(--adyen-sdk-animation-easing-linear, linear);
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adyen-checkout__payment-method__disable-confirmation--open {
  grid-template-rows: 1fr;
  opacity: 1;
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout__payment-method__disable-confirmation--open .adyen-checkout__payment-method__disable-confirmation__content {
  visibility: visible;
  padding: var(--adyen-sdk-spacer-040, 8px) var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__payment-method__disable-confirmation__buttons {
  display: flex;
}

.adyen-checkout__payment-method__disable-confirmation__button {
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  background: var(--adyen-sdk-color-background-critical-strong, #e22d2d);
  border: var(--adyen-sdk-border-width-s, 1px) solid transparent;
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  color: var(--adyen-sdk-color-label-on-color, #ffffff);
  cursor: pointer;
  display: block;
  height: auto;
  margin: 0 0 0 var(--adyen-sdk-spacer-040, 8px);
  padding: var(--adyen-sdk-spacer-040, 8px);
  width: auto;
}
.adyen-checkout__payment-method__disable-confirmation__button:hover, .adyen-checkout__payment-method__disable-confirmation__button:hover:focus {
  box-shadow: none;
  background: #d81e1e;
}
.adyen-checkout__payment-method__disable-confirmation__button:active, .adyen-checkout__payment-method__disable-confirmation__button:hover:active {
  background: #c11b1b;
  box-shadow: none;
}

.adyen-checkout__payment-method__disable-confirmation__button--remove,
.adyen-checkout__payment-method__disable-confirmation__button--remove:disabled {
  border-color: var(--adyen-sdk-color-label-on-color, #ffffff);
}

.adyen-checkout__payment-method__disable-confirmation__button--cancel,
.adyen-checkout__payment-method__disable-confirmation__button--cancel:disabled {
  border-color: transparent;
}