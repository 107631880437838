.adyen-checkout__voucher-result {
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  text-align: center;
  position: relative;
}

.adyen-checkout__voucher-result__top,
.adyen-checkout__voucher-result__bottom {
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-separator-primary, #dbdee2);
}

.adyen-checkout__voucher-result__top {
  padding: var(--adyen-sdk-spacer-110, 40px) 0 var(--adyen-sdk-spacer-090, 24px);
  border-radius: var(--adyen-sdk-border-radius-m, 8px) var(--adyen-sdk-border-radius-m, 8px) 0 0;
  border-bottom: 0;
}

.adyen-checkout__voucher-result__bottom {
  border-top: 0;
  border-radius: 0 0 var(--adyen-sdk-border-radius-m, 8px) var(--adyen-sdk-border-radius-m, 8px);
}

.adyen-checkout__voucher-result__separator {
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.adyen-checkout__voucher-result__separator__inner {
  width: 100%;
  border-top: 1px solid var(--adyen-sdk-color-separator-primary, #dbdee2);
}

.adyen-checkout__voucher-result__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: var(--adyen-sdk-spacer-110, 40px);
}

.adyen-checkout-link--voucher-result-instructions {
  display: inline-block;
}

.adyen-checkout__voucher-result__image__wrapper {
  display: block;
  position: relative;
  height: var(--adyen-sdk-spacer-120, 48px);
  margin: 0 var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout__voucher-result__image__wrapper::after {
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  box-shadow: var(--adyen-sdk-shadow-low, 0px 2px 4px rgba(0, 17, 44, 0.04), 0px 1px 2px rgba(0, 17, 44, 0.02));
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.adyen-checkout__voucher-result__image__wrapper:nth-child(2) {
  margin-left: 0;
}

.adyen-checkout__voucher-result__image__brand,
.adyen-checkout__voucher-result__image__issuer {
  height: var(--adyen-sdk-spacer-120, 48px);
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
}

.adyen-checkout__voucher-result__introduction {
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  text-align: center;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  max-width: 400px;
  margin: 0 auto;
}

.adyen-checkout__voucher-result__amount {
  margin: var(--adyen-sdk-spacer-090, 24px) auto 0;
  font-size: var(--adyen-sdk-text-subtitle-font-size, 1rem);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  text-align: center;
  font-weight: bold;
}

.adyen-checkout__voucher-result__surcharge {
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  text-align: center;
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  display: block;
  font-weight: normal;
}

.adyen-checkout__voucher-result__code__label {
  position: absolute;
  display: block;
  font-weight: normal;
  right: 0;
  left: 0;
  width: auto;
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  margin: 0 auto;
  user-select: none;
}

.adyen-checkout__voucher-result__code__label::before {
  content: "";
  position: absolute;
}

.adyen-checkout__voucher-result__code__label__text {
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  padding: 0 var(--adyen-sdk-spacer-040, 8px);
  letter-spacing: normal;
  line-height: 1;
}

.adyen-checkout__voucher-result__code__barcode {
  display: block;
  margin: 0 auto var(--adyen-sdk-spacer-040, 8px);
  max-width: 100%;
  height: var(--adyen-sdk-spacer-130, 56px);
  user-select: none;
}

.adyen-checkout__voucher-result__code {
  position: relative;
  font-size: var(--adyen-sdk-text-title-l-font-size, 1.5rem);
  text-align: center;
  letter-spacing: 1px;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  border-width: 1px 0;
  padding: var(--adyen-sdk-spacer-070, 16px) var(--adyen-sdk-spacer-120, 48px);
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  font-weight: bold;
  user-select: all;
  word-break: break-word;
  line-height: 1.2;
}

.adyen-checkout__voucher-result__actions {
  margin: 0 auto var(--adyen-sdk-spacer-100, 32px);
  max-width: 100%;
  min-width: 200px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.adyen-checkout__voucher-result__actions__item {
  margin: 0 var(--adyen-sdk-spacer-020, 4px);
}