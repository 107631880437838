.adyen-checkout__details-table {
  list-style: none;
  padding: 0;
  margin: -1px auto 0;
}
.adyen-checkout__details-table__item {
  display: flex;
  justify-content: space-between;
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  padding: var(--adyen-sdk-spacer-070, 16px) var(--adyen-sdk-spacer-090, 24px);
  border-top: 1px solid var(--adyen-sdk-color-separator-primary, #dbdee2);
  word-break: break-word;
}
.adyen-checkout__details-table__item:last-child {
  margin-bottom: 0;
}
.adyen-checkout__details-table__label {
  max-width: 50%;
  text-align: left;
}
.adyen-checkout__details-table__value {
  max-width: 50%;
  text-align: right;
  font-weight: bold;
}