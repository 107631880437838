.adyen-checkout__alert-message {
  display: flex;
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  margin: 0 0 var(--adyen-sdk-spacer-070, 16px);
  text-align: left;
  padding: var(--adyen-sdk-spacer-060, 12px);
  font-size: var(--adyen-sdk-text-caption-font-size, 0.75rem);
}

.adyen-checkout__alert-message--error {
  background: var(--adyen-sdk-color-background-critical-strong, #e22d2d);
}

.adyen-checkout__alert-message--info {
  background: var(--adyen-sdk-color-background-secondary, #f7f7f8);
}

.adyen-checkout__alert-message__icon {
  width: 14px;
  height: 14px;
  margin-right: var(--adyen-sdk-spacer-040, 8px);
}