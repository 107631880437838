.adyen-checkout__dropin {
  display: flex;
  flex-direction: column;
  gap: var(--adyen-sdk-spacer-100, 32px);
}

.adyen-checkout-payment-methods-container {
  display: flex;
  flex-direction: column;
  gap: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout-payment-methods-list-label {
  font-size: var(--adyen-sdk-text-subtitle-font-size, 1rem);
  font-weight: var(--adyen-sdk-text-subtitle-stronger-font-weight, 600);
  line-height: var(--adyen-sdk-text-subtitle-line-height, 26px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
}

.adyen-checkout__payment-methods-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout__payment-methods-list--loading {
  user-select: none;
  pointer-events: none;
}

.adyen-checkout__instant-payment-methods-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout__instant-payment-methods-list li {
  flex: 1 0 calc(50% - var(--adyen-sdk-spacer-070, 16px) / 2);
}

.adyen-checkout__payment-method {
  display: block;
}
.adyen-checkout__payment-method__details {
  overflow: hidden;
}
.adyen-checkout__payment-method__image {
  width: var(--adyen-sdk-spacer-110, 40px);
  height: 26px;
}
[dir=rtl] .adyen-checkout__payment-method__image__wrapper {
  margin-right: 0;
  margin-left: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout-pm-details-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 250ms ease;
  visibility: hidden;
}

.adyen-checkout-pm-details-wrapper[aria-hidden=false] {
  grid-template-rows: 1fr;
  visibility: visible;
}
.adyen-checkout-pm-details-wrapper[aria-hidden=false] .adyen-checkout__payment-method__details {
  overflow: visible;
}