.adyen-checkout-ctp__otp-checkbox-container {
  padding: var(--adyen-sdk-spacer-060, 12px);
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  background-color: #f7f8f9;
}

.adyen-checkout-ctp__otp-checkbox-container--checked {
  background-color: #F2F8FF;
}

.adyen-checkout-ctp__otp-checkbox-container label {
  cursor: pointer;
}

.adyen-checkout-ctp__section .adyen-checkout__field.adyen-checkout-ctp__otp-checkbox-container {
  margin-bottom: var(--adyen-sdk-spacer-020, 4px);
}

.adyen-checkout-ctp__otp-checkbox-info {
  color: #707070;
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
  margin: var(--adyen-sdk-spacer-020, 4px) 0 0;
}

.adyen-checkout-ctp__otp-readmore-button {
  all: unset;
  text-transform: lowercase;
  cursor: pointer;
  color: #0075ff;
}