.adyen-checkout__ctp-modal-header-image {
  display: block;
  margin: auto;
}
.adyen-checkout__ctp-modal-title {
  font-weight: 700;
  font-size: var(--adyen-sdk-text-title-m-font-size, 1.25rem);
  line-height: var(--adyen-sdk-spacer-090, 24px);
  padding: 0;
  margin: 0 0 var(--adyen-sdk-spacer-060, 12px);
}
.adyen-checkout__ctp-modal-text {
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout__ctp-modal-benefits {
  margin-left: 0;
  padding-left: var(--adyen-sdk-spacer-080, 20px);
}
.adyen-checkout__ctp-modal-benefits li {
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
  list-style: disc;
}

.adyen-checkout__modal-wrapper--ctp .adyen-checkout__modal {
  max-width: 464px;
}

.adyen_checkout-ctp__brand-wrapper--popup {
  justify-content: center;
  margin-bottom: var(--adyen-sdk-spacer-090, 24px);
}