.adyen-checkout-paybybank_AIS_DD {
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}
.adyen-checkout-paybybank_AIS_DD__description-header {
  margin: var(--adyen-sdk-spacer-000, 0px) var(--adyen-sdk-spacer-000, 0px) var(--adyen-sdk-spacer-020, 4px);
  font-size: var(--adyen-sdk-text-subtitle-font-size, 1rem);
  font-weight: var(--adyen-sdk-text-body-stronger-font-weight, 500);
  color: var(--adyen-sdk-color-label-primary, #00112c);
}
.adyen-checkout-paybybank_AIS_DD__description-body {
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  list-style-type: disc;
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  margin: 0;
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  line-height: 1.5;
}

@media (max-width: 330px) {
  .adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:nth-child(2) {
    display: none;
  }
}
@media (max-width: 360px) {
  .adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:nth-child(3) {
    display: none;
  }
}
@media (max-width: 390px) {
  .adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:nth-child(4) {
    display: none;
  }
}
.adyen-checkout__payment-method--paybybank_AIS_DD .adyen-checkout__payment-method__brand-number {
  text-overflow: clip;
  white-space: nowrap;
}