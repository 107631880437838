.adyen-checkout-form-instruction {
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-size: var(--adyen-sdk-text-caption-font-size, 0.75rem);
  font-weight: var(--adyen-sdk-text-body-font-weight, 400);
  margin-top: 0;
  margin-bottom: var(--adyen-sdk-spacer-070, 16px);
}
[dir=rtl] .adyen-checkout-form-instruction {
  padding-right: 0;
}