.adyen-checkout__dropdown__button-icon--left {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.adyen-checkout__dropdown__button-icon--left > img {
  margin-left: 0;
  margin-right: var(--adyen-sdk-spacer-060, 12px);
}

.adyen-checkout__dropdown {
  position: relative;
  max-width: 100%;
  width: 100%;
  font-size: var(--adyen-sdk-text-subtitle-font-size, 1rem);
}

.adyen-checkout__dropdown__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: inherit;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  background: inherit;
  text-decoration: none;
  outline: 0;
  padding: 0 var(--adyen-sdk-spacer-060, 12px);
  width: 100%;
  font-size: var(--adyen-sdk-text-subtitle-font-size, 1rem);
  height: var(--adyen-sdk-spacer-110, 40px);
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
  user-select: none;
  transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
}
[dir=rtl] .adyen-checkout__dropdown__button {
  padding: 7px var(--adyen-sdk-spacer-060, 12px) 7px var(--adyen-sdk-spacer-090, 24px);
}

.adyen-checkout__dropdown__button::after {
  position: absolute;
  content: "";
  height: var(--adyen-sdk-spacer-030, 6px);
  right: var(--adyen-sdk-spacer-070, 16px);
  width: var(--adyen-sdk-spacer-040, 8px);
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2216%22%20height=%2217%22%20viewBox=%220%200%2016%2017%22%20fill=%22none%22%3E%0A%20%20%3Cpath%20fill-rule=%22evenodd%22%20clip-rule=%22evenodd%22%20d=%22M4.46971%206.46967C4.7626%206.17678%205.23748%206.17678%205.53037%206.46967L8.00004%208.93934L10.4697%206.46967C10.7626%206.17678%2011.2375%206.17678%2011.5304%206.46967C11.8233%206.76256%2011.8233%207.23744%2011.5304%207.53033L8.53037%2010.5303C8.23747%2010.8232%207.7626%2010.8232%207.46971%2010.5303L4.46971%207.53033C4.17681%207.23744%204.17681%206.76256%204.46971%206.46967Z%22%20fill=%22#00112C%22/%3E%0A%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}
[dir=rtl] .adyen-checkout__dropdown__button::after {
  left: var(--adyen-sdk-spacer-070, 16px);
  right: auto;
}

.adyen-checkout__dropdown__button--active::after {
  transform: rotate(180deg);
}

.adyen-checkout__dropdown__button--disabled {
  opacity: 0.4;
}

.adyen-checkout__dropdown__button--readonly, .adyen-checkout__dropdown__button--readonly--active, .adyen-checkout__dropdown__button--readonly:hover, .adyen-checkout__dropdown__button--readonly:focus {
  background: var(--adyen-sdk-color-background-disabled, #eeeff1);
  border-color: transparent;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  cursor: not-allowed;
}

.adyen-checkout__dropdown__button--readonly::after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.19471 6.5646C3.59429 7.09797 4.39396 7.0986 4.79439 6.56587L7.78716 2.58424C8.28257 1.92514 7.81232 0.983398 6.98779 0.983398L1.01209 0.983398C0.188292 0.983398 -0.282154 1.92367 0.211778 2.58298L3.19471 6.5646Z' fill='%23B9C4C9'/%3E%3C/svg%3E%0A");
}

.adyen-checkout__dropdown__button--invalid {
  border-color: var(--adyen-sdk-color-outline-critical, #e22d2d);
}

.adyen-checkout__dropdown__button__icon {
  margin-right: var(--adyen-sdk-spacer-060, 12px);
  max-width: var(--adyen-sdk-spacer-110, 40px);
  height: 26px;
  border-radius: 3px;
}

.adyen-checkout__dropdown__button__text {
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  text-align: left;
}

.adyen-checkout__dropdown__button__secondary-text {
  margin-right: var(--adyen-sdk-spacer-070, 16px);
}

.adyen-checkout__filter-input {
  background: inherit;
  border: 0;
  color: var(--adyen-sdk-color-label-primary, #00112c);
  font-family: inherit;
  font-size: var(--adyen-sdk-text-subtitle-font-size, 1rem);
  height: 100%;
  padding: 0;
  width: 100%;
}
.adyen-checkout__filter-input:focus, .adyen-checkout__filter-input:active {
  outline: 0;
}
.adyen-checkout__filter-input[readonly] {
  border-color: transparent;
  color: var(--adyen-sdk-color-label-secondary, #5c687c) !important;
  cursor: not-allowed;
}

.adyen-checkout__dropdown__list {
  overflow-y: auto;
  display: none;
  position: absolute;
  z-index: 2;
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  max-height: 375px;
  box-shadow: 0 var(--adyen-sdk-spacer-010, 2px) 7px rgba(0, 15, 45, 0.3);
  width: 100%;
  background: var(--adyen-sdk-color-background-primary, #ffffff);
  list-style: none;
  padding: 0;
  margin: 0 0 var(--adyen-sdk-spacer-120, 48px);
}

.adyen-checkout__dropdown__list.adyen-checkout__dropdown__list--active {
  display: block;
  margin-top: var(--adyen-sdk-spacer-010, 2px);
}

.adyen-checkout__dropdown__element {
  display: flex;
  align-items: center;
  padding: var(--adyen-sdk-spacer-040, 8px);
  line-height: var(--adyen-sdk-text-body-line-height, 20px);
  border: var(--adyen-sdk-border-width-s, 1px) solid transparent;
  word-break: break-word;
  hyphens: auto;
  cursor: pointer;
  font-size: var(--adyen-sdk-text-body-font-size, 0.875rem);
  outline: 0;
  user-select: none;
  transition: background 0.2s ease-out, border-color 0.2s ease-out;
}
.adyen-checkout__dropdown__element .adyen-checkout__icon {
  position: absolute;
  right: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__dropdown__element:last-child {
  border-bottom: 0;
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active {
  background: rgba(230, 233, 235, 0.6);
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--selected {
  background: rgba(0, 102, 255, 0.1);
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--selected:hover,
.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--selected:focus,
.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--selected:active {
  background: rgba(0, 102, 255, 0.15);
}

.adyen-checkout__dropdown__element--disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.adyen-checkout__dropdown__element__icon {
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  margin-right: var(--adyen-sdk-spacer-060, 12px);
  max-width: var(--adyen-sdk-spacer-110, 40px);
  max-height: 26px;
}

.adyen-checkout__dropdown__element__text {
  flex-grow: 1;
}

.adyen-checkout__dropdown__element__secondary-text:not(:last-child) {
  margin-right: var(--adyen-sdk-spacer-040, 8px);
}

.adyen-checkout__dropdown__element__flag {
  margin-left: var(--adyen-sdk-spacer-040, 8px);
  margin-right: var(--adyen-sdk-spacer-050, 10px);
  max-width: 27px;
  max-height: 18px;
}

.adyen-checkout__dropdown + .adyen-checkout-input__inline-validation {
  right: var(--adyen-sdk-spacer-100, 32px);
}